<template>
  <div class="px-4 pt-4">
    <v-alert type="error" v-model="error" dismissible>Something went wrong.</v-alert>

    <v-card-actions class="mt-16" v-if="loadingResults">
      <v-spacer></v-spacer>
      <v-progress-circular indeterminate :width="7" :size="70"></v-progress-circular>
      <v-spacer></v-spacer>
    </v-card-actions>

    <SelectLocation v-if="locationData === null" @onSelectedLocation="onSelectedLocation"></SelectLocation>
    <SelectCourses v-if="locationData && !coursesSelected" @onSelectedCourses="onSelectedCourses" :locationData="locationData"></SelectCourses>
    <pre style="background-color:white">{{locationData}}</pre>
    <SelectTime v-if="locationData && coursesSelected && dateString === null" @onSelectedDate="onSelectedDate"></SelectTime>
    <SelectPlayers v-if="dateString !== null && numPlayers == null" @onSelectedPlayers="onSelectedPlayers"></SelectPlayers>


    <div v-if="times && dateString !== null && numPlayers !== null">
      <v-card-actions><v-spacer></v-spacer><v-btn @click="onStartOver">Start Over</v-btn><v-spacer></v-spacer></v-card-actions>
      <SearchResults :times="times" :numPlayers="numPlayers"></SearchResults>
    </div>
    <v-card-actions v-if="showFakeButton" class="mx-auto">
      <v-spacer></v-spacer>
      <v-btn @click="onFakeLocation">Fake Location</v-btn>
      <v-btn @click="onFakeIt">Fake It</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>
  import { callFunction } from '@/config/firebase.js';
  import isLocalhost from '@/config/isLocalhost.js';
  import SearchResults from '@/components/SearchResults.vue';
  import SelectTime from '@/components/SelectTime.vue';
  import SelectPlayers from '@/components/SelectPlayers.vue';
  import SelectLocation from "@/components/SelectLocation";
  import SelectCourses from "@/components/SelectCourses";
  import dayjs from "dayjs";

  export default {
    name: 'FetchTimes',
    components:{
      SearchResults,
      SelectLocation,
      SelectCourses,
      SelectTime,
      SelectPlayers,
    },
    created(){
      // DNC
      //this.onFakeLocation();
    },
    methods: {
      async fetchTimes(dateString){
        const data = await this.loading(callFunction('getTimes', {date: dateString}));
        this.times = data.times;
      },
      onSelectedLocation(location){
        this.locationData = location;
      },
      onSelectedDate(dayjsDay){
        this.dateString = dayjsDay.format('YYYY-MM-DD');
      },
      onSelectedPlayers(players){
        this.numPlayers = players;
        this.fetchTimes(this.dateString);
      },
      onStartOver(){
        this.locationData = null;
        this.times = null;
        this.dateString = null;
        this.numPlayers = null;
      },
      async loading(awaitable){
        let results = null;
        this.loadingResults = true;
        try{
          results = await awaitable;
        }
        catch(e){
          this.error = true;
          this.onStartOver();
          results = null;
        }
        this.loadingResults = false;
        return results;
      },
      onFakeLocation(){
        this.locationData = {
          city: "San Francisco",
          state: "CA",
          zip: "94103",
          lat: 37.7749,
          lng: -122.4194
        };
      },
      onSelectedCourses(array){
        this.coursesSelected = array;
      },
      async onFakeIt(){
        this.onFakeLocation();
        this.numPlayers = 1;
        this.dateString = dayjs().format('YYYY-MM-DD');

        const data = await this.loading(callFunction('getTimes', {date: this.dateString, fake:true}));

        this.times = data ? data.times : null;
      }
    },
    data(){
      return {
        // debug
        showFakeButton: isLocalhost,

        // state
        locationData: null,
        coursesSelected: null,
        dateString: null,
        numPlayers: null,
        times: null,

        // loading / error
        loadingResults: false,
        error: null,
      }
    }
  }
</script>

<!-- Deliberately not scoped. -->
<style>
#app {
  background:url("@/assets/golf.jpg") no-repeat center center fixed !important;
  background-size: cover;
}

#app .v-card{
  background-color:rgba(255, 255, 255, 0.9);
}

#app .v-data-table{
  background-color:rgba(255, 255, 255, 0.9);
}

</style>
