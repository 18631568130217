<template>
<div>
  <v-data-table
      :headers="headers"
      :items="filteredTimes"
      item-key="name"
      sort-by="datetime"
      class="elevation-2 mx-auto"
      show-group-by
      :items-per-page="10000"
      style="max-width:550px;"
      :hide-default-footer="true"
  >
    <template v-slot:item.course="{ item }">
      <div class="my-3">
        <div class="courseName">{{ courseInfo[item.course].name }}</div>
        <div class="caption">San Francisco, CA</div>
      </div>
    </template>
    <template v-slot:item.datetime="{ item }">
      <div class="courseName">{{ formatTime(item.datetime) }}</div>
    </template>
    <template v-slot:item.maxPlayers="{ item }">
      <div class="courseName">
      {{ formatPlayers(item.maxPlayers) }}
      </div>
    </template>
    <template v-slot:item.link="{ item }">
      <div class="courseName">
      <v-btn color="primary" :href="item.link" target="_blank" class="mr-2">Book</v-btn>
      </div>
    </template>
  </v-data-table>
</div>
</template>

<script>
import { VDataTable } from 'vuetify/lib'
import dayjs from 'dayjs';

export default{
  name:"SearchResults",
  components:{
    VDataTable
  },
  props: {
    times: Array,
    numPlayers:null
  },
  methods:{
    formatTime(dateTimeString){
      return dayjs(dateTimeString).format('h:mm A');
    },
    formatPlayers(maxPlayers){
      return `${maxPlayers} player${maxPlayers > 1 ? 's' : ''}`
    }
  },
  data () {
    return {
      headers: [
        {text: 'course', value: 'course', groupable: true},
        {text: 'max', value: 'maxPlayers', groupable: false },
        {text: 'time', value: 'datetime', groupable: false},
        {text: 'link', value: 'link', groupable: false}
      ],
      courseInfo:{
        gleneagles: {
          name: 'Gleneagles Golf Course'
        },
        fleming:{
          name: 'TPC Fleming'
        },
        goldengatepark:{
          name: 'Golden Gate Park Golf Course'
        },
        sharppark:{
          name: 'Sharp Park Golf Course'
        },
        lincolnpark:{
          name: 'Lincoln Park Golf Course'
        },
        hardingpark:{
          name: 'TPC Harding Park'
        },
        marinerspoint:{
          name: "Mariner's Point"
        }

      }
    }
  },
  computed:{
    filteredTimes(){
      return this.times.filter(item => item.maxPlayers >= this.numPlayers);
    }
  }
}
</script>
<style scoped>
.courseName{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
