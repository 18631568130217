<template>
  <v-card
      class="mx-auto"
      max-width="400"
  >
    <v-card-text>
      <h2 class="text-h6 mb-2">
        Where do you want to golf?
      </h2>
      <v-chip-group column v-model="selection">
        <v-chip :filter="!loadingGeolocation" outlined :disabled="loadingGeolocation || geolocationDenied" @click="tryGeolocation">
          <v-progress-circular indeterminate :size="20" class="mr-2" v-if="loadingGeolocation"></v-progress-circular>
          {{currentLocationString}}
        </v-chip>
        <v-chip filter outlined @click="zipCode = null; inputZipCode = null">
          <span v-if="zipCode">{{zipCodeDisplay ? zipCodeDisplay: zipCode}}</span>
          <span v-if="selection !== 1 && !zipCode">Enter Zip Code</span>
          <v-text-field class="select-location-zip"
                        v-if="selection === 1 && !zipCode"
                        v-model="inputZipCode"
                        ref="zipcode"
                        type="number"
                        style="max-width:80px;"
                        @blur="onBlurZipCode"
                        pattern="[0-9]*"></v-text-field>
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :disabled="!isValidSelection" @click="next">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {callFunction} from "@/config/firebase";

export default {
  name: 'SelectLocation',
  data(){
    return{
      geolocationSupported : !!navigator.geolocation,
      geolocationResults : null,
      geolocationDenied : false,
      loadingGeolocation : false,
      geolocationLat: null,
      geolocationLng: null,
      selection: null,
      zipCode: null,
      zipCodeDisplay: null,
      zipCodeLat:null,
      zipCodeLng:null,
      inputZipCode: null,
    }
  },
  methods:{
    async tryGeolocation(){
      if (this.geolocationResults){
        return;
      }
      this.loadingGeolocation = true;
      const successCallback = async (position) => {
        this.geolocationLat = position.coords.latitude;
        this.geolocationLng = position.coords.longitude;
        const results = await callFunction('reverseGeocode', {
          lat: this.geolocationLat, lng: this.geolocationLng
        });
        this.geolocationResults = `${results.city}, ${results.state} ${results.zip}`;
        this.loadingGeolocation = false;
      };
      const errorCallback = (error) => {
        console.log('geolocation error', error);
        this.geolocationDenied = true;
        this.loadingGeolocation = false;
        this.selection = null;

      }
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    },
    onBlurZipCode(){
      this.zipCode = null;
      this.zipCodeDisplay = null;
    },
    next(){
      switch(this.selection){
        case 0:
          this.$emit('onSelectedLocation', {
            lat: this.geolocationLat,
            lng: this.geolocationLng,
            location: this.geolocationResults
          });
          return;
        case 1:
          this.$emit('onSelectedLocation', {
            lat: this.zipCodeLat,
            lng: this.zipCodeLng,
            location: this.zipCodeDisplay
          });
          break;
      }

    }

  },
  watch:{
    selection(newVal){
      if (newVal === 1){
        this.$nextTick(() => {
          this.$refs.zipcode.$refs.input.focus();
        });
      }
    },
    inputZipCode(newVal){
      if (newVal && newVal.length === 5){
        this.zipCode = newVal;
      }
    },
    async zipCode(newVal){
      if (newVal && newVal.length === 5){
        this.zipCodeDisplay = null;
        const results = await callFunction('reverseGeocodeByZip', {zip: newVal});

        if (results === null){
          this.zipCode = null;
          this.inputZipCode = null;
          this.$nextTick(() => {
            this.$refs.zipcode.$refs.input.focus();
          });
          return;
        }
        this.zipCodeLat = results.lat;
        this.zipCodeLng = results.lng;
        this.zipCodeDisplay = `${results.city}, ${results.state} ${results.zip}`;
      }
    }
  },
  computed:{
    isValidSelection(){
      switch(this.selection){
        case 0:
          return !!this.geolocationResults;
        case 1:
          return this.zipCodeDisplay !== null;
      }
      return this.selection !== null;
    },
    currentLocationString(){
      if (this.loadingGeolocation){
        return "Checking for location...";
      }
      if (this.geolocationDenied){
        return "Cannot get location.";
      }
      return this.geolocationResults ?? 'Current Location';
    }
  }


}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
.select-location-zip input::-webkit-outer-spin-button,
.select-location-zip input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.select-location-zip input[type=number] {
  -moz-appearance: textfield;
}
</style>
