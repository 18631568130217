<template>
  <div>
    <FetchTimes />
  </div>
</template>

<script>
import FetchTimes from '@/components/FetchTimes.vue'

export default {
  name: 'HomeView',
  components: {
    FetchTimes
  }
}
</script>
