var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 mx-auto",staticStyle:{"max-width":"550px"},attrs:{"headers":_vm.headers,"items":_vm.filteredTimes,"item-key":"name","sort-by":"datetime","show-group-by":"","items-per-page":10000,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"courseName"},[_vm._v(_vm._s(_vm.courseInfo[item.course].name))]),_c('div',{staticClass:"caption"},[_vm._v("San Francisco, CA")])])]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"courseName"},[_vm._v(_vm._s(_vm.formatTime(item.datetime)))])]}},{key:"item.maxPlayers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"courseName"},[_vm._v(" "+_vm._s(_vm.formatPlayers(item.maxPlayers))+" ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"courseName"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","href":item.link,"target":"_blank"}},[_vm._v("Book")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }