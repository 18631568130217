<template>
  <div>
    <v-card
        class="mx-auto"
        max-width="400"
    >
      <v-card-text>
        <h2 class="text-h6 mb-2">
          How many players?
        </h2>
        <v-chip-group v-model="selection" column active-class="primary--text">
          <v-spacer></v-spacer>
          <v-chip outlined v-for="numPlayer in numPlayers" :key="numPlayer">
            {{numPlayer}}
          </v-chip>
          <v-spacer></v-spacer>
        </v-chip-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="selection === null" @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  data: () => ({
    numPlayers: [1,2,3,4],
    selection:3
  }),
  methods: {
    next(){
      this.$emit('onSelectedPlayers', this.numPlayers[this.selection])
    }
  }

}
</script>
