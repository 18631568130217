<template>
  <div>
    <v-card
        class="mx-auto"
        max-width="400"
    >
      <v-card-text>
        <h2 class="text-h6 mb-2">
          When do you want to golf?
        </h2>
        <v-chip-group v-model="selection" column>
          <v-chip filter outlined v-for="dayString in dayStrings" :key="dayString">
            {{dayString}}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="selection === null" @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  created(){
    const today = dayjs(new Date());
    for (let i=0; i<8; i++){
      const dayjsDay = today.add(i, 'day');
      switch(i){
        case 0:
          this.dayStrings.push('Today');
          break;
        case 1:
          this.dayStrings.push('Tomorrow');
          break;
        default:
          // day of the week.
          this.dayStrings.push(dayjsDay.format('dddd'))
      }
      this.dayjsDays.push(dayjsDay)
    }
  },
  data: () => ({
    dayjsDays:[],
    dayStrings:[],
    selection:null
  }),
  methods: {
    next(){
      this.$emit('onSelectedDate', this.dayjsDays[this.selection])
    }
  }

}
</script>
