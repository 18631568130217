<template>
  <div>
    <v-card
        class="mx-auto"
        max-width="400"
    >
      <v-card-text>
        <h2 class="text-h6 mb-2">
          Which courses would you like to play?
        </h2>
        <v-chip-group multiple v-model="selection" column active-class="primary--text">
          <v-chip v-for="course in courses" :key="course.id" filter>{{course.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="selection === null" @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {callFunction} from "@/config/firebase";

export default {
  data: () => {
    return {
      courses: null,
      selection:[]
    }
  },
  props:{
    locationData: Object,

  },
  async created(){
    this.courses = await callFunction('getCloseCourses', {lat:
      this.locationData.lat, lng: this.locationData.lng, count: 10})
  },
  methods: {
    next(){
      this.$emit('onSelectedCourses', [])
    }
  }

}
</script>
