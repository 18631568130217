import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import isLocalhost from "@/config/isLocalhost";

const config = {
    apiKey: "AIzaSyDH3kmxGrGET1MoNNNm6SsYkDXhY3dcjIM",
    authDomain: "teecondor-prod.firebaseapp.com",
    databaseURL: "https://teecondor-prod-default-rtdb.firebaseio.com",
    projectId: "teecondor-prod",
    storageBucket: "teecondor-prod.appspot.com",
    messagingSenderId: "694867883324",
    appId: "1:694867883324:web:7414204e62919f8f37da39",
    measurementId: "G-72PJKTQF0E"
}
const app = initializeApp(config);
const functions = getFunctions(app);
if (isLocalhost){
    console.log('functions: emulator');
    connectFunctionsEmulator(functions, "localhost", 5000);
}
else{
    console.log('functions: production');
}


export const callFunction = async (funcName, params) => {
    const callable = httpsCallable(functions, funcName);
    return (await callable(params)).data;
}

